import React from "react";
import './App.css';
import './index.css';

import sub0 from "./sub_bj3.png";
import sub1 from "./sub_snap.png";
import sub2 from "./sub_legendaria.png";
import sub3 from "./sub_guywithglasses.png";
import sub4 from "./sub_tnitr.png";

import {NavLink} from "react-router-dom";

function InfoboxButton(props) {
    console.log(props);
    return (
        <button className="bg-darkishbrown transition hover:bg-darkbrown px-5 py-2 text-white text-center text-lg font-bold rounded-lg mr-2 my-3" onClick={() => InfoboxF(props.link)}>
            {props.label}
        </button>
    );
}

function InfoboxF(link) {
    window.open(link, "_self");
}

function Infobox(props) {
    // props: image, alt, scale, title, desc, link, view
    let image;
    if (props.scale === 100) image = <img src={props.image} alt={props.alt} className="scale-100"/>;
    if (props.scale === 75) image = <img src={props.image} alt={props.alt} className="scale-75"/>;
    if (props.scale === 50) image = <img src={props.image} alt={props.alt} className="scale-50"/>;
    return (
        <div>
            <div className="bg-nicebrown grid grid-rows-3 md:grid-rows-1 grid-cols-1 md:grid-cols-3 md:gap-4 rounded-xl mx-5 lg:py-5 items-center">
                <div className="lg:mt-36 md:mt-0">
                    <div className="lg:-mt-28 flex justify-center">
                        {image}
                    </div>
                </div>
                <div className="row-span-2 md:col-span-2 my-auto grid grid-rows-2 gap-4 justify-items-center">
                    <div>
                        <p className="text-white text-center text-2xl font-bold mx-5">
                            {props.title}
                        </p>
                        <p className="text-white text-center text-lg mx-5">
                            {props.desc}
                        </p>
                    </div>
                    <div className="grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-2 gap-4">
                        <InfoboxButton label="Play" link={props.link}/>
                        <InfoboxButton label="Page" link={props.view}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

/*
function Smallbox(props) {
    // props: image, alt, scale, title, desc, link
    let image;
    if (props.scale === 100) image = <img src={props.image} alt={props.alt} className="scale-100"/>;
    if (props.scale === 75) image = <img src={props.image} alt={props.alt} className="scale-75"/>;
    if (props.scale === 50) image = <img src={props.image} alt={props.alt} className="scale-50"/>;
    return (
        <div>
            <div className="bg-nicebrown grid grid-rows-3 md:grid-rows-1 grid-cols-1 md:grid-cols-3 md:gap-4 rounded-xl mx-5 py-5 items-center">
                <div className="mt-36 md:mt-0">
                    <div className="flex justify-center">
                        {image}
                    </div>
                </div>
                <div className="row-span-2 md:col-span-2 my-auto grid grid-rows-2 gap-4 justify-items-center">
                    <div>
                        <p className="text-white text-center text-2xl font-bold mx-5">
                            {props.title}
                        </p>
                        <p className="text-white text-center text-lg mx-5">
                            {props.desc}
                        </p>
                    </div>
                    <div className="flex justify-center">
                        <InfoboxButton label="Play" link={props.link}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
*/

function Legacy() {
    return (
        <div className="bg-grey">
            <div className="pt-20">
                <p className="text-7xl md:text-8xl text-white text-center font-Staat pt-5">
                    LEGACY GAMES
                </p>
                <p className="text-xl md:text-2xl text-white text-center font-bold mt-4 md:-mt-16 mx-3">
                    These are our older games, made at a lesser quality. Who knows? Maybe we'll remake them someday.
                </p>
            </div>
            <NavLink className="mt-14 flex justify-center" to="/games/battle-jellies">
                <button><img src={sub0} alt="Battle Jellies 2" width="42.8%" className="mx-auto rounded-xl"/></button>
            </NavLink>
            <div className="grid grid-rows-2 grid-cols-2 gap-4 lg:gap-10 xs:mt-3 md:mt-12 mx-8 lg:mx-48 pb-10">
                <button onClick={() => {window.open("https://gamejolt.com/games/snap/709717");}}><img src={sub1} alt="SNAP!" className="mx-auto rounded-xl"/></button>
                <button onClick={() => {window.open("https://gamejolt.com/games/legendaria/737595");}}><img src={sub2} alt="Legendaria" className="mx-auto rounded-xl"/></button>
                <button onClick={() => {window.open("https://gamejolt.com/games/guy_with_glasses/699598");}}><img src={sub3} alt="Guy With Glasses" className="mx-auto rounded-xl"/></button>
                <button onClick={() => {window.open("https://gamejolt.com/games/nobody-in-the-room/745739");}}><img src={sub4} alt="Nobody in the Room" className="mx-auto rounded-xl"/></button>
            </div>
            
        </div>
    );
    
}

/*

<Infobox image={piano_img} alt="Piano Run" scale={100} title="Piano Run" link="https://pianorun.superphantom1.repl.co" view="/games/piano-run" desc="Piano Run is a simple yet exciting infinite platformer! You're the last broken piano, and you have to run from all the piano tuners attempting to fix you! Collect music notes to gain points! How long can you last?"/>
                <Infobox image={tnitr_img} alt="TNITR" scale={100} title="There's Nobody in the Room" link="https://gamejolt.com/games/nobody-in-the-room/745739" view="/games/tnitr" desc="You wake up in a strange room. You can't remember anything from before. Hesitantly, you search around, finding clues and unlocking doors. Then you see it. That strange face... that voice..."/>
                <Infobox image={lg_img} alt="NOTD" scale={100} title="Legendaria: Night of the Dead" link="https://gamejolt.com/games/legendaria/737595" view="/games/legendaria" desc="Defeat skeletons, gain points, and trade those points for weapons and potions! Survive for as long as possible, then compare your scores on the online leaderboard!"/>
                <Infobox image={gwg_img} alt="GWG" scale={100} title="Guy With Glasses" link="https://gamejolt.com/games/guy_with_glasses/699598" view="/games/guy-with-glasses" desc="Guy with Glasses is a combination of a platformer and RPG! Defeat Red and stop his malicious plans in taking over Blockville! Complete quests, uncover backstory, and get through platforming challenges!"/>
                <Infobox image={snap_img} alt="SNAP" scale={100} title="SNAP!" link="https://snapBeta.superphantom1.repl.co" view="/games/snap" desc="SNAP! is a unique puzzle game. In a tile-based world, snap your own character between tiles to get to the goal - but watch out! You only have a certain number of moves per stage. Can you get to the end?"/>
                <Infobox image={space_img} alt="Space" scale={100} title="JACK The Jelly Goes To Space" link="https://arcade.bloxels.co/64962745" view="/games/jack-the-jelly-space" desc="Taking place in JACK The Jelly, Jack has been through countless adventures. What's next? Space, of course! Journey through the mysterious Second Moon and find out the Space Jellies' true intentions!"/>



                <div className="bg-lightgrey py-5 mt-5">
                <p className="text-5xl text-white text-center font-Staat mt-4">
                    See what else we've made:
                </p>
                <div className="flex justify-center">
                    <button className="mt-5 bg-lightishgrey transition hover:bg-grey text-white text-center text-lg font-bold rounded-lg px-14 py-2" onClick={() => InfoboxF("/more")}>
                        More
                    </button>
                </div>
            </div>

*/

export default Legacy;